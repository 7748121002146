const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://f8qyy1f4v7.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://y9kpy3fuzf.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://ts4y2uia8k.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.0',
    HOSTNAME: 'https://locations.dev.exploration.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.exploration.forwoodsafety.com',
    WEBSOCKET: 'wss://qud3gskc58.execute-api.us-west-2.amazonaws.com/dev'
  },
};

export default config;