import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Col, Row, Divider, Radio, Icon, Button, Dropdown, Menu, Spin, Typography
} from 'antd';

import PhysicalLocationList from '../../Containers/PhysicalLocationList';
import PhysicalLocationStructure from '../../Containers/PhysicalLocationStructure';
import PhysicalLocationFilter from '../PhysicalLocationFilter';
import PhysicalLocationExporter from '../../Containers/PhysicalLocationExporter';
import styles from './styles.scss';
import Base from '../Base';

const { Title } = Typography;

export class PhysicalLocations extends Base {
  constructor(props) {
    super(props);

    this.state = {
      view: 'list',
      structureFilter: {},
      flatFilter: {},
      sort: null,
      loadingCompanyStructure: true,
      failedToloadRequiredData: false,
      showFilter: false,
      hasData: false,
      parentUuid: ''
    };

    this.onViewChange = this.onViewChange.bind(this);
    this.onStructureFilter = this.onStructureFilter.bind(this);
    this.onFlatFilter = this.onFlatFilter.bind(this);
    this.onSortingChange = this.onSortingChange.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.onDataFetch = this.onDataFetch.bind(this);
    this.onParentUuidChange = this.onParentUuidChange.bind(this);
  }

  componentDidMount() {
    this.getCompanyStructure();
  }

  async getCompanyStructure() {
    const { getCompanyStructure, t } = this.props;
    try {
      await super.dispatchWithAuth(getCompanyStructure);
      this.setState({ loadingCompanyStructure: false });
    } catch (error) {
      console.log(error);
      if (error.status !== 401) {
        super.handleError(t('failedToFetchCompanyData'));
        this.setState({
          loadingCompanyStructure: false,
          failedToloadRequiredData: true
        });
      }
    }
  }

  onViewChange(e) {
    this.setState({
      view: e.target.value,
      structureFilter: {},
      flatFilter: {},
      hasData: false,
    });
  }

  onFlatFilter(filter) {
    this.setState({
      flatFilter: filter,
      hasData: false,
    });
  }

  onStructureFilter(filter) {
    this.setState({
      structureFilter: filter,
      hasData: false,
    });
  }

  onSortingChange(sort) {
    this.setState({ sort });
  }

  onDataFetch(data) {
    this.setState({
      hasData: data.length > 0
    });
  }

  onParentUuidChange(parentUuid) {
    this.setState({
      parentUuid
    });
  }

  renderView() {
    const { view, structureFilter, flatFilter } = this.state;
    if (view === 'list') {
      return (
        <div className="physical-location-list">
          <PhysicalLocationList
            filter={flatFilter}
            onSortingChange={this.onSortingChange}
            onDataFetch={this.onDataFetch}
          />
        </div>
      );
    }

    return (
      <div className="physical-location-structure">
        <PhysicalLocationStructure
          filter={structureFilter}
          onDataFetch={this.onDataFetch}
          onParentUuidChange={this.onParentUuidChange}
        />
      </div>
    );
  }

  renderButtons(isMobile) {
    const { t } = this.props;
    const {
      flatFilter, structureFilter, sort, view, hasData, showFilter, parentUuid
    } = this.state;

    return (
      <Button.Group
        style={isMobile ? { marginTop: 30 } : { float: 'right' }}
      >
        <PhysicalLocationExporter
          filter={view === 'list' ? flatFilter : structureFilter}
          sort={sort}
          text={t('export')}
          style={isMobile ? { width: '100%', marginBottom: 15 } : {}}
          view={view}
          companyUuid={structureFilter['filter.companies']}
          parentUuid={parentUuid}
          disabledButton={!hasData}
        />
        <Dropdown
          trigger={['click']}
          overlay={this.renderBatchProcessMenu()}
        >
          <Button
            icon="retweet"
            style={isMobile ? { width: '100%', marginBottom: 15 } : {}}
          >
            {t('batchProcess')}
          </Button>
        </Dropdown>
        <Button
          icon="filter"
          style={{
            float: 'right',
            marginRight: '10px',
          }}
          onClick={() => this.toggleFilter(!showFilter)}
        >
          {showFilter ? 'Hide Filter Options' : 'Show Filter Options'}
        </Button>
      </Button.Group>
    );
  }

  renderBatchProcessMenu() {
    const { t } = this.props;
    return (
      <Menu>
        <Menu.Item key="new-batch-transaction">
          <Link to="/physical-location/batch-process">
            <Icon type="upload" />
            {' '}
            {t('newBatchProcess')}
          </Link>
        </Menu.Item>
        <Menu.Item key="view-batch-transaction">
          <Link to="/physical-location/batch-transaction">
            <Icon type="bars" />
            {' '}
            {t('transactionsList')}
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  toggleFilter(state) {
    this.setState({ showFilter: state });
  }

  render() {
    const { t } = this.props;
    const {
      view,
      loadingCompanyStructure,
      failedToloadRequiredData,
      showFilter
    } = this.state;

    if (loadingCompanyStructure || failedToloadRequiredData) {
      return <></>;
    }

    return (
      <Media query="(max-width: 769px)">
        { (isMobile) => (
          <>
            <Row type="flex" justify="center" align="top">
              <Col span={12}>
                <Title level={4} className={styles.title}>
                  {t('physicalLocations')}
                </Title>
                <Divider />
              </Col>

              <Col span={12} className="text-right">
                <Radio.Group
                  value={view}
                  buttonStyle="solid"
                  onChange={this.onViewChange}
                >
                  <Radio.Button value="list"><Icon type="bars" /></Radio.Button>
                  <Radio.Button value="tree"><Icon type="apartment" /></Radio.Button>
                </Radio.Group>
                <Divider style={{ marginTop: 16 }} />
              </Col>

            </Row>

            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <Spin
                  spinning={loadingCompanyStructure}
                  size="large"
                  style={{ marginTop: 40 }}
                >
                  {view === 'tree' ? (
                    <PhysicalLocationFilter
                      onFilter={this.onStructureFilter}
                      structureFilter
                      key="treeFilter"
                      showFilter={showFilter}
                      renderButtons={() => this.renderButtons(isMobile)}
                    />
                  ) : (
                    <PhysicalLocationFilter
                      onFilter={this.onFlatFilter}
                      structureFilter={false}
                      key="flatFilter"
                      showFilter={showFilter}
                      renderButtons={() => this.renderButtons(isMobile)}
                    />
                  )}
                </Spin>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                { this.renderView() }
              </Col>
            </Row>

          </>
        )}
      </Media>
    );
  }
}

PhysicalLocations.propTypes = {
  t: PropTypes.func.isRequired,
  getCompanyStructure: PropTypes.func.isRequired,
};

export default withTranslation(['physicalLocations', 'common'])(PhysicalLocations);
