import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { withTranslation } from 'react-i18next';
import { MaintenanceMode } from 'componentlibrary';
import Config from '../../Config';
import { routes } from '../../routes';

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshKey: null
    };
  }

  componentDidMount() {
    this.setAuth();
    this.getAppConfig();
  }

  componentDidUpdate() {
    const { context } = this.props;
    const { refreshKey } = this.state;

    if (context.refreshKey !== refreshKey) {
      this.refresh(context.refreshKey);
    }
  }

  componentWillUnmount() {
    const { wsDisconnect } = this.props;
    wsDisconnect();
  }

  async getAppConfig() {
    const { getAppConfig, wsConnect, configServiceFetchRequest } = this.props;
    await getAppConfig();
    configServiceFetchRequest(Config.configurationService.URL);
    wsConnect(Config.configurationService.WEBSOCKET);
    this.appConfigInterval = setInterval(getAppConfig.bind(this), 60 * 1000);
  }

  setAuth() {
    const { context, setAuth } = this.props;
    setAuth(context.getIdToken);
  }

  refresh(refreshKey) {
    const { history } = this.props;
    history.push('/physical-location');

    this.setState({
      refreshKey
    });
  }

  render() {
    const { t = {}, appConfig } = this.props;

    if (!appConfig) {
      return null;
    }

    if (appConfig && appConfig.maintenanceMode) {
      return (
        <MaintenanceMode
          title={t('maintenanceMode:serviceDown')}
          description={t('maintenanceMode:pleaseTryAgain')}
        />
      );
    }

    return (
      <div style={{ margin: '20px' }}>
        {renderRoutes(routes)}
      </div>
    );
  }
}

App.defaultProps = {
  appConfig: null,
};

App.propTypes = {
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  getAppConfig: PropTypes.func.isRequired,
  appConfig: PropTypes.object,
  setAuth: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  wsConnect: PropTypes.func.isRequired,
  configServiceFetchRequest: PropTypes.func.isRequired,
  wsDisconnect: PropTypes.func.isRequired,
};

export default withTranslation(['common', 'errors', 'maintenanceMode'])(withRouter(App));
