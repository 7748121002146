export const all = {
  key: 'ALL',
  text: 'filterForm:site.value.all',
  value: 'ALL'
};

const enabled = {
  key: 'ENABLED',
  text: 'filterForm:site.value.enabled',
  value: true,
};

const disabled = {
  key: 'DISABLED',
  text: 'filterForm:site.value.disabled',
  value: false,
};

export const siteOptions = [enabled, disabled];

export const allSiteOptions = [all, enabled, disabled];
