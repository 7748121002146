import { connect } from 'react-redux';
import WithDefaults from '../WithDefaults';
import BatchTransactionList from '../../Components/BatchTransactionsList';
import {
  getBatchTransactions
} from '../../reducers/physicalLocation';

const mapDispatchToProps = (dispatch) => ({
  getBatchTransactions(...args) {
    return getBatchTransactions(dispatch, ...args);
  }
});

export default connect(
  mapDispatchToProps
)(WithDefaults(BatchTransactionList));
