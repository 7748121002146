import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import { withTranslation } from 'react-i18next';
import {
  Row, Tree, Spin, Card, Empty, Button, Popover, Icon
} from 'antd';
import deepSearch from 'd-forest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import Base from '../Base';
import { inlineLoader } from '../../sass/modules/loader.module.scss';

export class PhysicalLocationStructure extends Base {
  static extractCompanyName(node) {
    if (!node.companies) {
      return (
        <span>&nbsp;</span>
      );
    }
    const companyNames = [];
    node.companies.forEach((comUuuid) => {
      companyNames.push(node.metadata.companyData[comUuuid].name);
    });
    return companyNames.join(', ');
  }

  static renderNodeDetail(node) {
    return (
      <>
        <p>
          <strong>Companies: </strong>
          {' '}
          {PhysicalLocationStructure.extractCompanyName(node)}
        </p>
        <p>
          <strong>UUID: </strong>
          {' '}
          {node.uuid}
        </p>
      </>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      structures: [],
      allStructures: [],
      page: 1,
      pageSize: 30,
      hasMoreSite: false,
      hasNodeClick: false,
    };

    this.loading = this.loading.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.handleNodeClick = this.handleNodeClick.bind(this);
    this.handlePhysicalLocationBack = this.handlePhysicalLocationBack.bind(this);
  }

  componentDidMount() {
    this.fetchStructure();
  }

  resetPagination(then) {
    this.setState({
      structures: [],
      allStructures: [],
      pageNumber: 1,
      pageSize: 30,
      hasMoreSite: false,
      hasNodeClick: false,
    }, then);
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (!equal(prevProps.filter, filter)) {
      this.resetPagination(this.fetchStructure);
    }
  }

  async fetchStructure() {
    const {
      t, getPhysicalLocationStructure, filter, onDataFetch, onParentUuidChange
    } = this.props;
    if (!filter['filter.companies']) {
      return;
    }
    const {
      pageNumber, pageSize, structures, allStructures
    } = this.state;

    const query = {
      'filter.depthLevel': filter['filter.depthLevel'],
      ...(filter['filter.includeSubLevelCompanies'] !== undefined && { 'filter.includeSubLevelCompanies': filter['filter.includeSubLevelCompanies'] }),
      ...(filter['filter.site'] !== undefined && { 'filter.site': filter['filter.site'] }),
      ...(filter['filter.status'] !== undefined && { 'filter.status': filter['filter.status'] }),
      'page.number': pageNumber,
      'page.size': pageSize
    };

    if (filter['filter.siteName']) {
      query['filter.siteName'] = filter['filter.siteName'];
    }

    try {
      this.loading(true);
      const payload = await super.dispatchWithAuth(
        getPhysicalLocationStructure,
        filter['filter.companies'],
        query
      );
      const { meta, data } = payload;
      onDataFetch(data);
      onParentUuidChange('');
      const updatedState = {
        hasMoreSite: pageNumber < meta['site.pagination'].pageCount,
        structures: structures.concat(data),
        allStructures: allStructures.concat(data),
      };
      this.setState(updatedState);
    } catch (err) {
      console.log(err);
      // Unauthorized errors are already handled in the reducer
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      }
      if (err.status === 403) {
        super.handleError(t('forbiddenError'));
      }
    } finally {
      this.loading(false);
    }
  }

  loadMore() {
    const { pageNumber } = this.state;
    this.setState({ pageNumber: pageNumber + 1 }, this.fetchStructure);
  }

  handleNodeClick({ uuid, children }) {
    if (!children.length) return;
    const filteredStructures = [deepSearch.findNode(this.state.allStructures,
      (node) => node.uuid === uuid)];
    const { onParentUuidChange } = this.props;
    onParentUuidChange(uuid);
    this.setState({
      ...this.state,
      structures: filteredStructures,
      loading: false,
      hasNodeClick: true,
    });
  }

  handlePhysicalLocationBack() {
    const { onParentUuidChange } = this.props;
    onParentUuidChange('');
    this.setState({
      ...this.state,
      structures: this.state.allStructures,
      hasNodeClick: false,
    });
  }

  renderTreeNodes(children, level) {
    if (!children) {
      return (<></>);
    }

    const { TreeNode } = Tree;
    return children.map((physicalLocation) => (
      <TreeNode
        selectable={false}
        title={(
          <Popover
            content={PhysicalLocationStructure.renderNodeDetail(physicalLocation)}
            title={`${physicalLocation.name} - Level ${level}`}
          >
            <span onClick={() => this.handleNodeClick(physicalLocation)}>
              {' '}
              {physicalLocation.name}
              {' '}
              [
              {physicalLocation.status}
              ]
            </span>
            &nbsp;
            {physicalLocation.companies.length > 1 && (
            <Icon type="alert" theme="twoTone" />
            )}
          </Popover>
        )}
        key={physicalLocation.uuid}
      >
        {this.renderTreeNodes(physicalLocation.children, level + 1)}
      </TreeNode>
    ));
  }

  loading(state) {
    this.setState({ loading: state });
  }

  render() {
    const { t, filter } = this.props;
    const {
      loading, structures, pageNumber, hasMoreSite, hasNodeClick
    } = this.state;

    if (!filter['filter.companies']) {
      return (<Empty description={t('structureSelectCompanyToStart')} />);
    }

    if (loading && pageNumber === 1) {
      return (
        <Spin
          size="large"
          style={{ marginTop: 20 }}
          className={inlineLoader}
        />
      );
    }

    if (!loading && structures.length === 0) {
      return (
        <Empty message={t('common.noRecordsFound')} />
      );
    }

    return (
      <Row style={{ marginTop: '10px' }}>
        <Card>
          {hasNodeClick && (
          <span>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              onClick={this.handlePhysicalLocationBack}
              style={{ cursor: 'pointer' }}
              swapOpacity
            />
          </span>
          )}
          <Tree
            defaultExpandAll
            autoExpandParent
          >
            {this.renderTreeNodes(structures, 0)}
          </Tree>
          {loading && (
            <Spin
              size="large"
              className={inlineLoader}
            />
          )}
          {!loading && hasMoreSite && (
            <Button
              type="link"
              onClick={this.loadMore}
            >
              {t('structureLoadMore')}
            </Button>
          )}
        </Card>
      </Row>
    );
  }
}

PhysicalLocationStructure.defaultProps = {
};

PhysicalLocationStructure.propTypes = {
  t: PropTypes.func.isRequired,
  getPhysicalLocationStructure: PropTypes.func.isRequired,
  getIdToken: PropTypes.func.isRequired,
  filter: PropTypes.object,
  onDataFetch: PropTypes.func,
  onParentUuidChange: PropTypes.func
};

export default withTranslation(['physicalLocations'])(PhysicalLocationStructure);
