import React from 'react';
import PropTypes from 'prop-types';
import { message, notification } from 'antd';

/*
  Base Component for common / shared class methods
*/
class Base extends React.Component {
  static showMessage(infoMessage, timeout = 10) {
    message.info(infoMessage, timeout);
  }

  static hideMessage() {
    message.destroy();
  }

  // Returns the company or physical location name only if ACTIVE otherwise
  // the name with the status as a suffix
  static nameWithStatus(name, status) {
    if (status === 'ACTIVE') {
      return name;
    }

    return `${name} [${status}]`;
  }

  constructor(props) {
    super(props);

    this.notificationKey = 'base';
    this.showMessage = Base.showMessage.bind(this);
    this.hideMessage = Base.hideMessage.bind(this);
  }

  // Displays an ant design error notification
  handleError(error, title) {
    const { t } = this.props;

    this.clearNotification();

    notification.error({
      message: title || t('common:unexpectedError'),
      description: error,
      duration: 0,
      key: this.notificationKey
    });
  }

  // Used for any callback related logic upon data retrieval
  onDataRetrieved(...args) {
    const { onDataRetrieved } = this.props;
    if (onDataRetrieved) {
      onDataRetrieved(...args);
    }
  }

  // Calls a function (dispatcher) which appends the ID Token (authToken)
  // along with any passed in arguments
  async dispatchWithAuth(dispatcher, ...args) {
    const { getIdToken } = this.props;
    const token = await getIdToken();
    const response = await dispatcher(token, ...args);
    return response;
  }

  clearNotification() {
    notification.close(this.notificationKey);
  }

  render() {
    return null;
  }
}

Base.defaultProps = {
  onDataRetrieved: null
};

Base.propTypes = {
  t: PropTypes.func.isRequired,
  getIdToken: PropTypes.func.isRequired,
  onDataRetrieved: PropTypes.func
};

export default Base;
