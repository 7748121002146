import { connect } from 'react-redux';

import PhysicalLocationExporter from '../../Components/PhysicalLocationExporter';
import { exportList, exportStructure, getExportTransactionStatus } from '../../reducers/physicalLocation';
import WithDefaults from '../WithDefaults';

const mapDispatchToProps = (dispatch) => ({
  exportList(...args) {
    return exportList(dispatch, ...args);
  },
  exportStructure(...args) {
    return exportStructure(dispatch, ...args);
  },
  getExportTransactionStatus(...args) {
    return getExportTransactionStatus(dispatch, ...args);
  }
});

export default connect(mapDispatchToProps)(WithDefaults(PhysicalLocationExporter));
