import Config from '../Config';
import { api } from './api';

const PHYS_LOCATION_GET_FULL_COMPANY_STRUCTURE_DATA = 'PHYS_FULL_COMPANY_STRUCTURE_DATA';

export function companyDefaultState() {
  return {
    companyStructure: null
  };
}

export default (state = companyDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case PHYS_LOCATION_GET_FULL_COMPANY_STRUCTURE_DATA:
      return {
        companyStructure: payload.data.attributes
      };
    default:
      return state;
  }
};

export async function getCompanyStructure(dispatch, token) {
  const params = {
    endpoint: `${Config.apiGateway.COMPANY_APIGATEWAY_URL}/company-structure`,
    method: 'GET'
  };
  const payload = await api(token, params);
  dispatch({
    type: PHYS_LOCATION_GET_FULL_COMPANY_STRUCTURE_DATA,
    payload
  });
  return payload;
}
