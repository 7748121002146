import { connect } from 'react-redux';

import PhysicalLocationList from '../../Components/PhysicalLocationList';
import { getPhysicalLocationList } from '../../reducers/physicalLocation';
import WithDefaults from '../WithDefaults';

const mapDispatchToProps = (dispatch) => ({
  getPhysicalLocationList(...args) {
    return getPhysicalLocationList(dispatch, ...args);
  }
});

export default connect(mapDispatchToProps)(WithDefaults(PhysicalLocationList));
