import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Col, Pagination, Row, Spin
} from 'antd';
import equal from 'deep-equal';
import { Empty, ResponsiveTable } from 'componentlibrary';
import styles from './styles.modules.scss';

import Base from '../Base';
import { defaultSort } from '../../reducers/physicalLocation';

const pageSizeOptions = ['25', '50', '100'];

const sortFieldMap = {
  name: 'name',
  'metadata.parentName': 'parentName',
  companyName: 'companies',
  isSite: 'site',
  locationType: 'locationType',
  productType: 'productType',
  classType: 'classType',
  status: 'status'
};

export class PhysicalLocationList extends Base {
  static booleanIcon(value) {
    if (!value) {
      return (
        <span>&nbsp;</span>
      );
    }

    return (
      <FontAwesomeIcon
        icon={faCheckCircle}
        className="md-icon"
        color="green"
      />
    );
  }

  static extractCompanyName(companies, metadata) {
    if (!companies) {
      return (
        <span>&nbsp;</span>
      );
    }

    const companyNames = [];
    companies.forEach((comUuuid) => {
      companyNames.push(metadata.companyData[comUuuid].name);
    });

    return companyNames.join(', ');
  }

  static transformDataForDisplay(data) {
    return data.map((_item) => {
      const item = { ..._item.attributes };

      item.companyName = PhysicalLocationList.extractCompanyName(item.companies, item.metadata);
      item.isSite = PhysicalLocationList.booleanIcon(item.site);

      return item;
    });
  }

  static pageSizeSetting() {
    const pageSize = parseInt(sessionStorage.getItem('physical.location.pageSize'), 10);
    if (Number.isNaN(pageSize) || pageSize < 1) {
      return parseInt(pageSizeOptions[0], 10);
    }

    return pageSize;
  }

  constructor(props) {
    super(props);

    this.state = {
      fetchResponse: {
        meta: {
          pagination: {
            resultCount: 0
          }
        },
        data: []
      },
      loading: true,
      sort: this.sortMeta,
      limit: PhysicalLocationList.pageSizeSetting(),
      offset: 0,
      page: 1,
    };

    this.changeSort = this.changeSort.bind(this);
    this.loading = this.loading.bind(this);
    this.onShowSizeChange = this.onShowSizeChange.bind(this);
    this.resetPagination = this.resetPagination.bind(this);
  }

  componentDidMount() {
    this.fetchList();
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (!equal(prevProps.filter, filter)) {
      this.resetPagination(this.fetchList);
    }
  }

  get limit() {
    return this.state.limit;
  }

  get offset() {
    return this.state.offset;
  }

  get currentPage() {
    return (this.offset / this.limit) + 1;
  }

  get sortMeta() {
    const { onSortingChange } = this.props;

    const currentConfig = defaultSort;
    if (onSortingChange) {
      onSortingChange(currentConfig);
    }
    return currentConfig;
  }

  get tableHeader() {
    const { t } = this.props;
    const { sort } = this.state;

    const headerItem = (key, label, width) => ({
      key,
      label,
      sortFn: () => this.changeSort(key),
      isCurrentSortField: sort.headerKey === key,
      isReverse: sort.reverse === true,
      width
    });

    return [
      headerItem('name', t('name'), '22%', true),
      headerItem('metadata.parentName', t('parent'), '22%', false),
      headerItem('isSite', t('site'), '6%', false),
      headerItem('companyName', t('company'), '16%', false),
      headerItem('locationType', t('location'), '8%', false),
      headerItem('productType', t('product'), '8%', false),
      headerItem('classType', t('class'), '8%', false),
      headerItem('status', t('status'), '10%', true),
    ];
  }

  getResultCount() {
    const { fetchResponse } = this.state;
    const { meta = {} } = fetchResponse;
    const { pagination = {} } = meta;
    const { resultCount = 0 } = pagination;
    return resultCount;
  }

  async fetchList() {
    const {
      t, getPhysicalLocationList, filter, onDataFetch
    } = this.props;
    const { sort, limit, page } = this.state;
    try {
      this.loading(true);
      const payload = await super.dispatchWithAuth(
        getPhysicalLocationList,
        filter,
        sort,
        limit,
        page
      );
      this.setState({ fetchResponse: payload });
      onDataFetch(payload.data);
    } catch (err) {
      // Unauthorized errors are already handled in the reducer
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      }
      if (err.status === 403) {
        super.handleError(t('forbiddenError'));
      }
    } finally {
      this.loading(false);
    }
  }

  handlePageChange(page = this.currentPage, limit = this.limit) {
    this.setState({
      limit,
      offset: (page - 1) * limit,
      page
    }, this.fetchList);
  }

  onShowSizeChange(current, pageSize) {
    // reset back to the first page changing the page size
    this.handlePageChange(1, pageSize);
    sessionStorage.setItem('physical.location.pageSize', pageSize);
  }

  changeSort(itemKey) {
    const { onSortingChange } = this.props;
    const { sort } = this.state;
    let reverse = false;

    // changing the column name to match with backend
    const headerKey = itemKey;
    const key = sortFieldMap[headerKey];

    if (headerKey === sort.headerKey) {
      reverse = !sort.reverse;
    }

    const newSortKey = {
      key,
      headerKey,
      reverse
    };

    this.setState({
      sort: newSortKey
    }, this.fetchList);

    if (onSortingChange) {
      onSortingChange(newSortKey);
    }
  }

  resetPagination(then) {
    this.setState({
      offset: 0,
      page: 1
    }, then);
  }

  loading(state) {
    this.setState({ loading: state });
  }

  render() {
    const { t } = this.props;
    const { loading, limit, fetchResponse } = this.state;
    const { data } = fetchResponse;
    const resultCount = this.getResultCount();

    return (
      <Spin
        spinning={loading}
        size="large"
        style={{
          marginTop: 40,
          position: 'fixed',
          top: '20%',
          bottom: '20%'
        }}
      >
        <Row style={{ marginBottom: 20 }}>
          <Col>
            {resultCount > 0 && t('resultCount', {
              resultCount,
              item: resultCount > 1 ? t('physical-locations').toLowerCase() : t('physical-location').toLowerCase()
            })}
          </Col>
        </Row>
        <ResponsiveTable
          header={this.tableHeader}
          data={PhysicalLocationList.transformDataForDisplay(data)}
        />
        {resultCount > 0
        && (
        <Pagination
          className={styles.pagination}
          showSizeChanger
          onShowSizeChange={this.onShowSizeChange}
          pageSizeOptions={pageSizeOptions}
          locale={{ items_per_page: `/ ${t('page')}` }}
          current={this.currentPage}
          pageSize={limit}
          total={resultCount}
          showTotal={(total, range) => t('common:currentRange', { rangeFrom: range[0], rangeTo: range[1], total })}
          style={{ marginTop: 30 }}
          onChange={(page) => this.handlePageChange(page)}
        />
        )}
        {
          !loading && data.length === 0 && (
            <Empty message={t('common:noRecordsFound')} />
          )
        }
      </Spin>
    );
  }
}

PhysicalLocationList.propTypes = {
  t: PropTypes.func.isRequired,
  getPhysicalLocationList: PropTypes.func.isRequired,
  filter: PropTypes.object,
  onSortingChange: PropTypes.func,
  onDataFetch: PropTypes.func,
};

export default withTranslation(['physicalLocations', 'common'])(PhysicalLocationList);
